import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const AboutOne = () => {
  const [selectedCourse, setSelectedCourse] = useState("Java Full Stack");

  const courseData = {
    "Java Full Stack": {
      title: "Java Full Stack",
      description: `Java Full Stack course covers a range of topics from Java programming to front-end technologies like HTML, CSS, JavaScript, Angular, and backend technologies like Spring Boot, Hibernate, and RESTful Web Services. By the end of the course, you’ll be ready to work as a full-stack developer.`,
      accordionData: [
        { title: "Core Java Concepts", content: "Learn Java fundamentals: Variables, Loops, Arrays, Functions, etc." },
        { title: "Frontend Technologies", content: "Learn HTML, CSS, JavaScript, and Angular for building dynamic websites." },
        { title: "Backend Development with Java", content: "Learn Spring Boot, Hibernate, and RESTful APIs for backend development." },
        { title: "Database Management", content: "Learn MySQL, PostgreSQL, and other databases for storing and querying data." },
        { title: "Web Services & Microservices", content: "Learn how to develop RESTful APIs and Microservices using Java." },
      ],
      carouselData: ["Trainings by Certified Experts", "100% Placement Assistance", "Affordable Fees", "Free Demo Sessions", "Flexible Timings"],
    },
    "MERN Stack": {
      title: "MERN Stack",
      description: `MERN Stack covers MongoDB, Express.js, React.js, and Node.js. This course provides full-stack JavaScript knowledge from front-end React to back-end Node.js with a NoSQL database MongoDB.`,
      accordionData: [
        { title: "MongoDB", content: "Learn NoSQL database MongoDB for storing data efficiently." },
        { title: "Express.js & Node.js", content: "Learn how to build RESTful APIs with Express.js and Node.js." },
        { title: "React.js", content: "Learn React.js for building interactive UIs and single-page applications." },
        { title: "Database Management with MongoDB", content: "Learn MongoDB for storing and retrieving data in a NoSQL format." },
        { title: "Building Full-Stack Applications", content: "Integrate MongoDB, Express.js, React.js, and Node.js to create a full-stack application." },
      ],
      carouselData: ["Trainings by Certified Experts", "100% Placement Assistance", "Affordable Fees", "Free Demo Sessions", "Flexible Timings"],
    },
    "Testing": {
      title: "Testing",
      description: `This Testing course focuses on Manual Testing, Automation Testing using tools like Selenium, and understanding testing methodologies for efficient QA practices.`,
      accordionData: [
        { title: "Manual Testing", content: "Learn the fundamentals of manual testing including test case writing and execution." },
        { title: "Automation Testing with Selenium", content: "Learn automation testing with Selenium WebDriver to automate browser actions." },
        { title: "Testing Tools & Frameworks", content: "Understand various testing tools and frameworks like JUnit, TestNG, and Jenkins." },
        { title: "Bug Tracking & Reporting", content: "Learn how to report bugs using tools like JIRA and Bugzilla." },
        { title: "Performance & Load Testing", content: "Learn performance testing techniques and tools like JMeter." },
      ],
      carouselData: ["Trainings by Certified Experts", "100% Placement Assistance", "Affordable Fees", "Free Demo Sessions", "Flexible Timings"],
    },
  };

  const handleCourseChange = (course) => {
    setSelectedCourse(course);
  };

  return (
    <div className="container">
      <div className="row">
        <h3 className="py-3">About {courseData[selectedCourse].title} Training</h3>

        {/* Course Selection */}
        <div className="col-md-12 mb-4">
          <button
            className="btn fw-bold text-white me-2 px-5"
            onClick={() => handleCourseChange("Java Full Stack")}
            style={{background:'#ed2d16'}}
          >
            Java Full Stack
          </button>
          <button
            className="btn fw-bold text-white me-2 px-5"
            onClick={() => handleCourseChange("MERN Stack")}
            style={{background:'#ed2d16'}}
          >
            MERN Stack
          </button>
          <button
            className="btn fw-bold text-white me-2 px-5"
            onClick={() => handleCourseChange("Testing")}
            style={{background:'#ed2d16'}}
          >
            Testing
          </button>
        </div>

        {/* First half (Course Details) */}
        <div className="col-md-8">
          <div className="mb-4">
            <p>{courseData[selectedCourse].description}</p>
          </div>

          {/* Accordion for About Course */}
          <div className="accordion" id="accordionFlushExample">
            {courseData[selectedCourse].accordionData.map((item, index) => (
              <div className="" key={index}>
                <h2 className="" id={`flush-heading${index}`}>
                  <button
                    style={{
                      backgroundColor: "#ed2d16",
                      color: "white",
                      fontWeight: "bold",
                      borderRadius: "6px",
                    }}
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapse${index}`}
                    aria-expanded="false"
                    aria-controls={`flush-collapse${index}`}
                  >
                    {item.title}
                  </button>
                </h2>
                <div
                  id={`flush-collapse${index}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`flush-heading${index}`}
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body p-3" style={{ backgroundColor: "#ffc1c1" }}>
                    {item.content}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Second half (Carousel and Lead Form) */}
        <div className="col-md-4">
          {/* Carousel */}
          <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
              {courseData[selectedCourse].carouselData.map((item, index) => (
                <div key={index} className={`carousel-item ${index === 0 ? "active" : ""} p-3 rounded-2 text-light`} style={{ background: "#ed2d16" }}>
                  {item}
                </div>
              ))}
            </div>
          </div>

          {/* Lead generation form */}
          <div
            className="card rounded-4 border-0 my-2 col-md-12 shadow bg-light"
            id="led_gen_form"
            style={{ position: "sticky", top: "100px" }}
          >
            <div className="card-body" style={{ backgroundColor: "#fff", borderRadius: "8px" }}>
              <div className="text-dark pb-3 text-center">
                <b>Course Enquiry</b>
              </div>
              <form action="process.php" method="post">
                <div className="mb-3">
                  <input
                    type="text"
                    name="enq_name"
                    className="form-control rounded-0"
                    placeholder="Enter Name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    name="enq_email"
                    className="form-control rounded-0"
                    placeholder="Enter Email"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="number"
                    name="enq_phone"
                    className="form-control rounded-0"
                    placeholder="Enter Phone No*"
                    required
                  />
                </div>
                <div className="mb-3 d-flex">
                  <input
                    type="search"
                    name="course"
                    className="form-control rounded-0"
                    placeholder="Interested Course"
                  />
                </div>
                <div className="d-grid">
                  <button
                    type="submit"
                    className="btn rounded-3"
                    style={{ backgroundColor: "#ed2d16", color: "white" }}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOne;
