import React from "react";
import "../global.css";
import { useNavigate } from "react-router-dom"; // Import useNavigate for routing

const Hero = ({ courseTitle, courseDescription }) => {
  const navigate = useNavigate(); // Hook for programmatic navigation

  // Static content for course highlights
  const staticCourseHighlights = [
    { title: "Course Duration", value: "3-4 Months" },
    { title: "Mode of Training", value: "Online / Classroom" },
    { title: "100% Placement Assistance", value: "Guaranteed" },
    { title: "Expert Faculties", value: "10+ Years Experienced" },
  ];

  return (
    <div
      className="container-fluid py-3"
      style={{
        backgroundColor: "black",
        color: "white",
      }}
    >
      <div className="container">
        <div className="align-items-center">
          <div className="d-flex">
            {/* Course Name and its Rating */}
            <div className="col-md-8">
              <h1 className="fw-bold" style={{ color: "#ed2d16" }}>
                {courseTitle} <span className="text-white">Training In Pune</span>
              </h1>
              <br />
              <p style={{ fontSize: "14px", textAlign: "justify" }}>
                {courseDescription}
              </p>
              <div className="d-flex justify-content-between">
                <div className="course-ratings-stars">
                  <i className="text-warning fa fa-star" aria-hidden="true"></i>
                  <i className="text-warning fa fa-star" aria-hidden="true"></i>
                  <i className="text-warning fa fa-star" aria-hidden="true"></i>
                  <i className="text-warning fa fa-star" aria-hidden="true"></i>
                  <i className="text-warning fa fa-star-half" aria-hidden="true"></i>
                  <span>5 out of 4.5</span>
                </div>
              </div>
              <hr />

              {/* Course Highlights */}
              <div className="d-none d-md-block">
                <div className="row">
                  {staticCourseHighlights.map((highlight, index) => (
                    <div
                      key={index}
                      className="col-3 text-center pt-3 m-2"
                      style={{
                        borderRight: "1px solid gray",
                        fontSize: "15px",
                        backgroundColor: "#ffc1c1",
                        borderRadius: "8px",
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      <p>{highlight.title}</p>
                      <p>{highlight.value}</p>
                    </div>
                  ))}
                </div>
              </div>

              {/* Three Buttons related to course */}
              <div className="d-flex justify-content-between row my-2">
                {/* Placement Records Button */}
                <button
                  onClick={() => navigate("/placementsstatistics")}
                  className="btn btn-outline-light rounded-1 px-3 col my-2 shadow"
                >
                  PLACEMENT RECORDS
                </button>

                {/* Download Brochure Button */}
                <a
                  href="/brochure.pdf"
                  download="Brochure.pdf"
                  className="btn btn-light rounded-1 fw-bold col mx-2 my-2 shadow"
                  style={{ color: "#ed2d16" }}
                >
                  DOWNLOAD BROCHURE
                </a>

                {/* Interview Questions Button */}
                <button
                  type="button"
                  className="btn btn-outline-light rounded-1 col mx-2 my-2 shadow"
                >
                  INTERVIEW QUESTIONS
                </button>
              </div>
            </div>

            {/* Watch Live Class and Free Counselling Section */}
            <div className="col-md-4 d-flex justify-content-center d-none d-md-flex">
              <div className="course-banner-sections-right">
                <div className="d-flex justify-content-center align-items-center">
                  <a
                    href="https://wa.link/jphzq1"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-success px-5 mt-3"
                    style={{
                      background: "#fff",
                      color: "#ed2d16",
                      border: "1px solid #fff",
                      fontWeight: 700,
                      fontSize: "18px",
                      transition: ".3s",
                      textDecoration: "none",
                      borderRadius: "6px",
                    }}
                  >
                    {" "}
                    FREE COUNSELING
                  </a>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <a
                    href="https://wa.link/jphzq1"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-success px-5 mt-3"
                    style={{
                      background: "#ed2d16",
                      color: "#fff",
                      border: "1px solid #ed2d16",
                      fontWeight: 700,
                      fontSize: "18px",
                      transition: ".3s",
                      textDecoration: "none",
                      borderRadius: "6px",
                    }}
                  >
                    {" "}
                    Book a Free Demo
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Dynamic Content for Testing Course, Java Full Stack, and MERN Stack
const courseData = [
  {
    title: "Java Full Stack",
    description:
      "Master back-end and front-end development with our Java Full Stack program, including Spring Boot, Hibernate, and React.",
  },
  {
    title: "MERN Stack",
    description:
      "Dive into full-stack development using MongoDB, Express, React, and Node.js for modern web applications.",
  },
  
  {
    title: "Software Testing With Selenium",
    description:
      "Learn manual and automation testing with tools like Selenium, JUnit, and TestNG to ensure software quality.",
  },

  {
    title: "Data Analytics",
    description:
      "Learn to analyze data effectively with tools like Excel, Power BI, and Python, and make data-driven decisions.",
  },
  {
    title: "Data Science",
    description:
      "Master data analysis, visualization, and machine learning techniques using Python, R, and TensorFlow.",
  },
  {
    title: "Cloud Computing",
    description:
      "Gain expertise in cloud platforms like AWS, Azure, and Google Cloud to manage and deploy scalable applications.",
  },


];

export const HeroSection = () => {
  const [activeCourseIndex, setActiveCourseIndex] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setActiveCourseIndex((prevIndex) => (prevIndex + 1) % courseData.length);
    }, 2000); // Change course title every 5 seconds
    return () => clearInterval(interval);
  }, []);

  const activeCourse = courseData[activeCourseIndex];
  return (
    <Hero
      courseTitle={activeCourse.title}
      courseDescription={activeCourse.description}
    />
  );
};

export default HeroSection;
