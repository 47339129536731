import React from "react";
import "./ContactUs.css";
import AnimatedContactHero from "../components/AnimatedContactHero/AnimatedContactHero";

const ContactUs = () => {
  const handleOnSubmit = (e) => {
    e.preventDefault();
    console.log("Hello from contact");
  };

  return (
    <>
      <div className="main-contact-bg " >
        <AnimatedContactHero />
        <div className="contact-container">
          <iframe
            title="contactus"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.593064709822!2d73.92755356967923!3d18.502083031282!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c1f7630e173d%3A0xa824aaa1d4fce91c!2s163%2C%204%2F163%2C%20Bhosale%20Garden%20Rd%2C%20Aru%20Nagar%2C%20Bhosale%20Nagar%2C%20Hadapsar%2C%20Pune%2C%20Maharashtra%20411028!5e0!3m2!1sen!2sin!4v1734083417276!5m2!1sen!2sin"
          width="100%"
          height="400"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
      </div>
      <div
        className="container contact-box-container"
        style={{ marginTop: "-100px" }}
      >
        <div className="contact-box">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="currentColor"
            className="bi bi-geo-alt-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
          </svg>
          <h4>Address</h4>
          <p>
            FL-E.89 SNO.163A BHOSALE GARDEN,HADAPSAR Pune,
            Maharashtra-411004
          </p>
        </div>
        <div className="contact-box">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="currentColor"
            className="bi bi-telephone-fill"
            viewBox="0 0 16 16"
          >
            <path d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
          </svg>
          <h4>Phone</h4>
          <p>+91 9561181155</p>
        </div>
        <div className="contact-box">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="currentColor"
            className="bi bi-envelope-fill"
            viewBox="0 0 16 16"
          >
            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
          </svg>
          <h4>Email</h4>
          <p>support@speedupinfo<br />tech.in
          </p>
        </div>
        <div className="contact-box">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="currentColor"
            className="bi bi-browser-chrome"
            viewBox="0 0 16 16"
          >
            <path d="M16 8a8 8 0 0 1-7.022 7.94l1.902-7.098a3 3 0 0 0 .05-1.492A3 3 0 0 0 10.237 6h5.511A8 8 0 0 1 16 8M0 8a8 8 0 0 0 7.927 8l1.426-5.321a3 3 0 0 1-.723.255 3 3 0 0 1-1.743-.147 3 3 0 0 1-1.043-.7L.633 4.876A8 8 0 0 0 0 8m5.004-.167L1.108 3.936A8.003 8.003 0 0 1 15.418 5H8.066a3 3 0 0 0-1.252.243 2.99 2.99 0 0 0-1.81 2.59M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4" />
          </svg>
          <h4>Website</h4>
          <p>www.speedupinfotech<br />.com</p>
        </div>
      </div>
    </div >

      {/* Form */ }
      < h1
  style = {{
    textAlign: "center", // Center align the text
      margin: "50px auto", // Set margin to center horizontally
        color: "black", // Adjust text color as needed
          borderBottom: "3px solid #ed2d16",
            width: "300px",
        }
}
      >
  Get in Touch
      </h1 >
  <div className="contact-form-container">
    <form onSubmit={handleOnSubmit} className="contact-form">
      <div className="form-group">
        <input
          type="text"
          className="contact-form-input"
          placeholder="Full Name"
          required
        />
      </div>
      <div className="form-group">
        <input
          type="email"
          className="contact-form-input"
          placeholder="Enter Your Mail"
          required
        />
      </div>
      <div className="form-group">
        <input
          type="tel"
          className="contact-form-input"
          placeholder="Mobile Number"
          required
        />
      </div>
      <div className="form-group">
        <textarea
          className="contact-form-input"
          rows="5"
          placeholder="Message..."
          required
        ></textarea>
      </div>
      <button type="submit" className="contact-form-btn">
        Submit Message
      </button>
    </form>
  </div>
{/* End of Form */ }
    </>
  );
};

export default ContactUs;
