import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const FAQ = () => {
  return (
    <div className="container-fluid" id="faq">
      <div className="container">
        <h3 className="text-start py-3">Training FAQs - Speedup Infotech</h3>
        <div className="row">
          {/* Left side */}
          <div className="col-md-8 my-3">
            <div
              className="accordion accordion-flush"
              id="accordionExample"
              style={{ textAlign: "justify" }}
            >
              {/* FAQ 1 */}
              <div className="accordion-item border shadow">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    What is MERN Stack, and why is it in demand?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body p-3">
                    MERN Stack is a JavaScript-based framework comprising MongoDB, Express.js, React.js, and Node.js. It is in high demand due to its ability to build dynamic, responsive, and scalable web applications with ease.
                  </div>
                </div>
              </div>

              {/* FAQ 2 */}
              <div className="accordion-item border shadow">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    What is the duration of the Java Full Stack Training at Speedup Infotech?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body p-3">
                    The Java Full Stack Training program at Speedup Infotech is a comprehensive course that spans 4-6 months, covering foundational to advanced topics.
                  </div>
                </div>
              </div>

              {/* FAQ 3 */}
              <div className="accordion-item border shadow">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Do you provide live projects for students?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body p-3">
                    Yes, Speedup Infotech emphasizes real-world experience. We offer live projects in all our training programs to help students gain hands-on expertise.
                  </div>
                </div>
              </div>

              {/* FAQ 4 */}
              <div className="accordion-item border shadow">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Are there any prerequisites for enrolling in the MERN Stack course?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body p-3">
                    No prerequisites are required. Basic knowledge of JavaScript is beneficial, but we cover all fundamentals in the initial sessions to bring all students up to speed.
                  </div>
                </div>
              </div>

              {/* FAQ 5 */}
              <div className="accordion-item border shadow">
                <h2 className="accordion-header" id="headingFive">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Do you provide placement assistance after training?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body p-3">
                    Yes, we provide 100% placement assistance, including mock interviews, resume building, and job referrals. Our students have been placed in top companies.
                  </div>
                </div>
              </div>

              {/* FAQ 6 */}
              <div className="accordion-item border shadow">
                <h2 className="accordion-header" id="headingSix">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Are the training sessions online or offline?
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body p-3">
                    We offer both online and offline training sessions to accommodate students from different locations. Flexible batch timings are also available.
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right side */}
          <div className="col-md-4 my-3">
            <div className="card shadow border">
              <div className="card-body">
                <h3 className="card-title">Need Help?</h3>
                <p className="card-text">
                  Get in touch with us for personalized guidance and support on
                  our training courses.
                </p>
                <h5>Contact Us:</h5>
                <p>
                  <strong>Phone:</strong> +91 – 7620830992
                </p>
                <p>
                  <strong>Email:</strong> info@speedupinfotech.com
                </p>
                <h5>Follow Us:</h5>
                <div>
                  <a href="#" className="btn btn-outline-primary btn-sm me-1">
                    Facebook
                  </a>
                  <a href="#" className="btn btn-outline-info btn-sm me-1">
                    Twitter
                  </a>
                  <a href="#" className="btn btn-outline-danger btn-sm">
                    Instagram
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
