import React from "react";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Dummy components for the example routes
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Career from "./pages/Career";
import Footer from "./components/Footer/Footer";
import Blog from "./pages/Blog";
import LogoDesign from "./pages/LogoDesign";
import Websitedev from "./pages/Websitedev";
import Androiddev from "./pages/Androiddev";
import Itstaffing from "./pages/Itstaffing";
import HireFromUs from "./pages/HireFromUs";
import Placement from "./pages/Placement";
import RunningBanner from "./components/RunningBanner/RunningBanner";

function App() {
  return (
    <Router>
      <Navbar />
      <RunningBanner />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/career" element={<Career />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/logodesign" element={<LogoDesign />} />
        <Route path="/websitedev" element={<Websitedev />} />
        <Route path="/androidios" element={<Androiddev />} />
        <Route path="/itstaffing" element={<Itstaffing />} />
        <Route path="/hirefromus" element={<HireFromUs />} />
        <Route path="/placementsstatistics" element={<Placement />} />
        {/* Add other routes here */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
