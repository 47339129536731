import React from "react";
import Hero from "../components/Hero/Hero";
import "../components/global.css";
import ProgramCredentials from "../components/ProgramCredentials/ProgramCredentials";
import CourseDuration from "../components/CourseDuration/CourseDuration";
import AboutOne from "../components/AboutOne/AboutOne";
import ApplicationTechnologies from "../components/ApplicationTechnologies/ApplicationTechnologies";
import Curriculum from "../components/Curriculum/Curriculum";
import MockInterviews from "../components/MockInterviews/MockInterviews";
import RelatedCourses from "../components/RelatedCourses/RelatedCourses";
import Testimonials from "../components/Testimonials/Testimonials";
import Feedback from "../components/Feedback/Feedback";
import FAQ from "../components/FAQ/FAQ";
import Certificate from "../components/Certificate/Certificate";
import ProgramHighlights from "../components/ProgramHighlights/ProgramHighlights";
import LearnAndPractice from "../components/LearnAndPractice/LearnAndPractice";
import FaqSection from "../components/FAQSection/FAQSection";
import Clients from '../components/Client/Clients'

const Home = () => {
  return (
    <>
      <Hero />
      <Clients/>
      <AboutOne />
      <ApplicationTechnologies />
      <CourseDuration />
      <ProgramCredentials />
      <Curriculum />
      <FaqSection />
      <MockInterviews />
      <LearnAndPractice />
      <ProgramHighlights />
      <Certificate />
      <FAQ />
      <Feedback />
      <Testimonials />
      <RelatedCourses />
    </>
  );
};

export default Home;
