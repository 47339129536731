import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import './RelatedCourses.css'; // Link to a custom CSS file for additional styling

const RelatedCourses = () => {
  return (
    <div className="container-fluid py-5" id="related-courses">
      <div className="container">
        <h3 className="text-center py-3 text-dark fw-bold" style={{ fontSize: '2rem', fontWeight: '600' }}>Explore Our Related Courses</h3>
        <div className="row d-flex flex-wrap justify-content-center">
          {/* MERN Stack */}
          <div className="col-12 col-md-3 card rounded-3 shadow m-3 border-0 course-card">
            <a
              href="#"
              className="text-decoration-none"
            >
              <div className="card-body text-center p-4">
                <h6 className="card-title" style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                  MERN Stack Development
                </h6>
                <p className="card-text text-muted">Master MongoDB, Express, React, and Node.js for full-stack web development.</p>
              </div>
            </a>
          </div>

          {/* Java Courses */}
          <div className="col-12 col-md-3 card rounded-3 shadow m-3 border-0 course-card">
            <a
              href="#"
              className="text-decoration-none"
            >
              <div className="card-body text-center p-4">
                <h6 className="card-title" style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                  Java Spring Framework
                </h6>
                <p className="card-text text-muted">Build enterprise-level Java applications using the Spring Framework.</p>
              </div>
            </a>
          </div>

          <div className="col-12 col-md-3 card rounded-3 shadow m-3 border-0 course-card">
            <a
              href="#"
              className="text-decoration-none"
            >
              <div className="card-body text-center p-4">
                <h6 className="card-title" style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                  Java Hibernate ORM
                </h6>
                <p className="card-text text-muted">Learn Hibernate ORM for efficient database management in Java applications.</p>
              </div>
            </a>
          </div>

          {/* JavaScript Testing */}
          <div className="col-12 col-md-3 card rounded-3 shadow m-3 border-0 course-card">
            <a
              href="#"
              className="text-decoration-none"
            >
              <div className="card-body text-center p-4">
                <h6 className="card-title" style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                  JavaScript Testing with Jest
                </h6>
                <p className="card-text text-muted">Master Jest for testing JavaScript applications with ease and speed.</p>
              </div>
            </a>
          </div>

          {/* More JavaScript Testing */}
          <div className="col-12 col-md-3 card rounded-3 shadow m-3 border-0 course-card">
            <a
              href="#"
              className="text-decoration-none"
            >
              <div className="card-body text-center p-4">
                <h6 className="card-title" style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                  Selenium WebDriver for Testing
                </h6>
                <p className="card-text text-muted">Learn how to use Selenium WebDriver for automating web applications testing.</p>
              </div>
            </a>
          </div>

          {/* Backend Testing */}
          <div className="col-12 col-md-3 card rounded-3 shadow m-3 border-0 course-card">
            <a
              href="#"
              className="text-decoration-none"
            >
              <div className="card-body text-center p-4">
                <h6 className="card-title" style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                  Backend Testing with Mocha & Chai
                </h6>
                <p className="card-text text-muted">Learn Mocha and Chai for Node.js unit and integration testing.</p>
              </div>
            </a>
          </div>

          {/* Full Stack Java */}
          <div className="col-12 col-md-3 card rounded-3 shadow m-3 border-0 course-card">
            <a
              href="#"
              className="text-decoration-none"
            >
              <div className="card-body text-center p-4">
                <h6 className="card-title" style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                  Full Stack Java Development
                </h6>
                <p className="card-text text-muted">A comprehensive course that covers front-end, back-end, and database management in Java.</p>
              </div>
            </a>
          </div>

          {/* UI/UX Design */}
          <div className="col-12 col-md-3 card rounded-3 shadow m-3 border-0 course-card">
            <a
              href="#"
              className="text-decoration-none"
            >
              <div className="card-body text-center p-4">
                <h6 className="card-title" style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                  UI/UX Design for Developers
                </h6>
                <p className="card-text text-muted">Learn UI/UX design principles to enhance the user experience of your applications.</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelatedCourses;
