import React from "react";
import employee1 from "../assets/employee1.png";
import employee2 from "../assets/employee2.png";
import employee3 from "../assets/employee3.png";
import employee4 from "../assets/employee4.png";
import graphics from "../assets/graphics.png";
import about2 from "../assets/aboutimg2.png";
import about4 from "../assets/aboutimg4.png";
import about5 from "../assets/aboutimg5.png";
import studentImg from "../assets/student.png";
import trainer1 from "../assets/trainer-pr.png";
import rocketimg from '../assets/rocket.png';
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <>
      <div className="">
        <h1 className="title mt-5">
          <span
            className="highlight"
            style={{ background: "none", marginTop: "60px", color: '#ed2d16' }}
          >
            Your Journey{" "}
          </span>
          Starts Here
        </h1>

        {/* *************************************************************************** */}
        {/* <div className="button-row">
          <button className="journey-button">Demo Session</button>
          <button className="journey-button">Enroll</button>
          <button className="journey-button">Study Material & Syllabus</button>
          <button className="journey-button">Online / Offline Training</button>
        </div>

        <div className="button-row">
          <button className="journey-button">Course Certificate</button>
          <button className="journey-button">Soft Skill Session</button>
          <button className="journey-button">Mock Interview</button>
          <button className="journey-button">Live Project Work</button>
        </div>

        <div className="button-row">
          <button className="journey-button">Assured Placement</button> */}
        {/*        </div> */}

        {/* *************************************************************************** */}

        <div className="container-fluid">
          <div className="row my-3">
            <div className="col-md-12 text-center">
              <img src={rocketimg} alt="" className="rocketImg" />

            </div>
          </div>
        </div>
      </div>

      {/* ----------------------Second Section start---------------------- */}

      <div className="about-container1">
        <div className="about-text1">
          <h1 className="about-title">Speedup Infotech</h1>
          <p className="about-description">
            Speedup Infotech is the perfect choice for aspirers who are looking for comprehensive and engaging training in
            <b> MERN Stack</b>, <b>Java Full Stack</b>, and <b>Software Testing</b> in Pune.
            Our experienced trainers will guide you through the fundamentals of MongoDB, Express.js, React.js, Node.js,
            Java technologies, and testing methodologies, enabling you to build powerful web applications or master quality assurance skills.
            Whether you aim to create robust web solutions from scratch or enhance your current skillset, our industry-standard technologies
            and practical approach will help you excel.
          </p>

        </div>
        <div className="about-image1">
          <img src={about4} alt="impact-graph" style={{ marginLeft: "80px" }} />
        </div>
      </div>
      {/* ----------------------Second Section end---------------------- */}

      {/* section third start */}
      <div className="about-container1">
        <div className="about-image1">
          <img src={about2} alt="impact-graph" />
        </div>
        <div className="about-text1">
          <h1 className="" style={{ color: "black" }}>
            Why <span className="about-title">Speedup Infotech</span>{" "}
          </h1>
          <p className="about-description">
            Learn MERN fundamentals, from setting up tools to building REST APIs.
            Master MongoDB CRUD operations and real-time communication with Node.js.
            Integrate front-end and back-end seamlessly with secure practices.
            Java Full Stack <br /><br />

            Build scalable apps using Java, Spring Boot, and Hibernate.
            Create RESTful APIs and work with databases like MySQL.
            Develop full-stack solutions with Angular/React and deploy securely.
            Software Testing <br /><br />

            Master manual and automation testing with tools like Selenium and TestNG.
            Learn Agile testing, bug tracking, and API testing.
            Apply best practices to ensure secure, high-quality applications.
          </p>
        </div>
      </div>

      {/* section third end  */}

      {/* ***************************************************************************} */}

      {/* secton fourth start */}
      <div className="journey-section">
        <h1 className="journey-title">
          <span className="highlight" style={{ background: "none" }}>
            All You Have To Do This{" "}
          </span>
          To Start Journey With
          <br /> Speedup Infotech
        </h1>

        <img src={about5} alt="" className="journeyimg" />
      </div>

      {/* ***************************************************************************} */}

      {/* secton fourth end */}

      {/* --------------fifth section starts here ------------------------------- */}
      {/* <div className="impactful-section"> */}
      <div className="section-header">
        <h1 className="section-title">
          Driving impactful
          <span className="highlight-text" style={{ color: "white" }}>
            {" "}
            Career Results
          </span>
        </h1>
      </div>
      <div className="about-container1">
        <div className="about-image1">
          <img className="impact-image" src={studentImg} alt="impact-graph" />
        </div>
        <div className="about-text1">
          <h1 className="about-title">
            Speedup Infotech <br />{" "}
            <span style={{ color: "black", fontSize: "23px" }}>
              Super Toppers
            </span>
          </h1>
          <p className="about-description">
            Speed Up Infotech has been <b> transformative</b>
            <b>experience </b> for me. As one of their <b> student</b>, I can
            confidently say that their training programs are
            <b> top Notch</b>.The hands-on approach and real-world projects have
            not only honed my <b>technical skills</b> but also boosted my
            confidence. The <b>support</b> and
            <b> guidance</b> from the trainers and staff have been invaluable.{" "}
            <b>Thanks to Speed Up Infotech,</b> I feel well-prepared and excited
            to embark on my career in the <b>IT industry </b>. I highly
            recommend Speed Up Infotech to anyone looking to kickstart their
            career in tech.
          </p>
        </div>
      </div>
      {/* </div> */}

      {/* --------------fifth section starts here ------------------------------- */}

      {/* --------------------Sixth section starts here ---------------------------- */}
      {/* <div style={{ display: "flex", marginTop: "30px", marginBottom: "30px" }}>
        <div
          style={{
            backgroundColor: "#ed2d16",
            width: "700px",
            height: "500px",
          }}
        >
          <div style={{ padding: "30px", paddingLeft: "60px" }}>
            <h1 style={{ color: "#fff" }}>
              Carrier<span style={{ color: "black" }}> Blogs</span>
              <br />
              Pratik Sable, Our <br />
              <span style={{ color: "black" }}>Corporate Trainer Talks</span>
            </h1>
            <p style={{ color: "#fff" }}>
              It's a long established fact that a reader will
              <br />
              be distracted by the readable content of a page
              <br />
              when looking at its layout. The point of using
              <br />
              Lorem Ipsum is that it has a more-or-less normal
              <br />
              distribution of letters, as opposed to using
              <br />
              'Content here'
              <br />
            </p>
            <h2 style={{ color: "#fff" }}>Pratik Sable</h2>
            <p style={{ color: "#fff" }}>Corporate Trainer</p>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#ed2d16",
            width: "500px",
            height: "500px",
            borderTopRightRadius: "50%",
            borderBottomRightRadius: "50%",
          }}
        >
          <img src={trainer1} alt="impact-graph" />
        </div>
      </div> */}

      <div className="about-container">
        <div className="about-left">
          <div className="about-content">
            <h1>
              Carrier<span> Blogs</span>
              <br />
              Pratik Sable, Our <br />
              <span>Corporate Trainer Talks</span>
            </h1>
            <p style={{ textAlign: "left" }}>
              It's a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here'
              <br />
            </p>
            <h2>Pratik Sable</h2>
            <p>Corporate Trainer</p>
          </div>
        </div>
        <div className="about-right">
          <img src={trainer1} alt="impact-graph" />
        </div>
      </div>

      {/* --------------------Sixth section starts here ---------------------------- */}



      {/* Second Testimonial card start */}
      <div className="about-container1">
        <div className="about-text1">
          <h1 className="about-title">Employee</h1>
          <h3>Testimonial</h3>
          <p style={{ fontWeight: "bold" }}>
            SpeedUp Infotech is a hub of innovation and growth, offering a
            platform where ideas flourish and relationships thrive. As a{" "}
            <b>Business Development Associate, </b> I've experienced firthand
            the company's commitment to excellence and its unwavering support
            for its employees. It's truly inspiring to be part of a team that
            values creativity, collaboration, and continuous imporvement.
          </p>
          <h2>Pooja Lende</h2>
          <p>Business Developer</p>
        </div>
        <div className="about-image1">
          <img
            src={employee1}
            alt="impact-graph"
            style={{ marginLeft: "80px" }}
          />
        </div>
      </div>
      {/* Second Testimonial card end */}







      {/* --------------------Seventh section starts here ---------------- */}
    </>
  );
};

export default AboutUs;
