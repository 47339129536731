import React, { useState } from "react";
import { Card, Button, Form, Container, Row, Col } from "react-bootstrap";

const Curriculum = () => {
  const [courseType, setCourseType] = useState("MERN");

  const getCourseData = () => {
    switch (courseType) {
      case "MERN":
        return {
          title: "MERN Stack Course Curriculum",
          syllabusDownload: "syllabus/MERN-Stack.pdf",
          courseDescription: (
            <>
              <p>Learn MongoDB, Express, React, and Node.js in this full-stack development course. Ideal for aspiring web developers looking to master both front-end and back-end technologies.</p>
              <ul>
                <li>Introduction to Full Stack Development and MERN Stack</li>
                <li>Setting up the Development Environment for MERN</li>
                <li>Deep Dive into MongoDB: Collections, Documents, and CRUD operations</li>
                <li>Building RESTful APIs with Express.js</li>
                <li>Integrating APIs with MongoDB</li>
                <li>Frontend Development with React.js: Components, Props, and State</li>
                <li>Managing React Components and Lifecycle Methods</li>
                <li>State Management with Redux</li>
                <li>Backend Development with Node.js and Express.js</li>
                <li>Authentication & Authorization with JWT (JSON Web Tokens)</li>
                <li>Real-Time Web Applications using WebSockets</li>
                <li>Building and Consuming APIs</li>
                <li>Data Validation and Error Handling in Express</li>
                <li>MongoDB Aggregation Framework</li>
                <li>Asynchronous Programming in Node.js with Callbacks, Promises, and Async/Await</li>
                <li>Project Deployment with Heroku, Netlify, or AWS</li>
                <li>Version Control using Git and GitHub</li>
                <li>Introduction to Testing in MERN Stack with Mocha and Chai</li>
                <li>Best Practices for Writing Clean and Maintainable Code</li>
              </ul>
            </>
          ),
        };
      case "Java Full Stack":
        return {
          title: "Java Full Stack Course Curriculum",
          syllabusDownload: "syllabus/Java-Full-Stack.pdf",
          courseDescription: (
            <>
              <p>Master Java for both front-end and back-end development. Learn Angular for the front-end, Spring Boot for the back-end, and Hibernate for database management in this comprehensive course.</p>
              <ul>
                <li>Introduction to Full Stack Development and Java</li>
                <li>Core Java Fundamentals: OOP, Collections, Exception Handling</li>
                <li>Setting up the Java Development Environment</li>
                <li>Frontend Development with Angular: Components, Directives, and Services</li>
                <li>Two-Way Data Binding and Forms in Angular</li>
                <li>Routing and Navigation in Angular</li>
                <li>Building REST APIs with Spring Boot</li>
                <li>Implementing CRUD Operations with Spring Boot and Hibernate</li>
                <li>Connecting Java Backend with Frontend using REST</li>
                <li>Spring Boot Security: JWT Authentication and Authorization</li>
                <li>Database Integration with MySQL, PostgreSQL, or MongoDB</li>
                <li>Handling Form Data in Java Web Applications</li>
                <li>Microservices Architecture with Spring Cloud</li>
                <li>Consuming REST APIs with Angular and displaying data</li>
                <li>Dependency Injection and Aspect-Oriented Programming (AOP) in Spring</li>
                <li>Using Maven for Project Management and Dependency Handling</li>
                <li>Testing with JUnit and Mockito</li>
                <li>Logging and Exception Handling in Spring Boot</li>
                <li>Introduction to Deployment: Docker and Cloud Platforms</li>
                <li>Best Practices in Java Full Stack Development</li>
                <li>Version Control using Git and GitHub</li>
              </ul>
            </>
          ),
        };
      case "Testing":
        return {
          title: "Software Testing Course Curriculum",
          syllabusDownload: "syllabus/Software-Testing.pdf",
          courseDescription: (
            <>
              <p>Become a software testing expert with this course. Learn manual testing, automation testing with Selenium, API testing with Postman, and performance testing methodologies.</p>
              <ul>
                <li>Introduction to Software Testing and SDLC</li>
                <li>Different Types of Testing: Manual, Automated, Regression, and Acceptance Testing</li>
                <li>Test Case Design Techniques: Boundary Value Analysis, Equivalence Partitioning</li>
                <li>Understanding and Writing Test Plans</li>
                <li>Manual Testing: UI, Functional, and Usability Testing</li>
                <li>Automated Testing with Selenium WebDriver: Setup, Basic Commands, and Best Practices</li>
                <li>Writing Automation Scripts in Java and Python</li>
                <li>Framework Design for Automation Testing</li>
                <li>API Testing with Postman: Request Types, Response Validation, and Scripting</li>
                <li>Performance Testing using JMeter</li>
                <li>Security Testing Fundamentals</li>
                <li>Continuous Integration (CI) and Continuous Testing (CT) Concepts</li>
                <li>Version Control with Git for Testers</li>
                <li>Test-Driven Development (TDD) in Software Testing</li>
                <li>Mock Testing with Mockito and Stubbing</li>
                <li>Exploratory Testing Techniques</li>
                <li>Bug Reporting and Tracking Tools: Jira, Bugzilla</li>
                <li>Mobile App Testing Fundamentals</li>
                <li>Database Testing: SQL Queries and Data Validation</li>
                <li>Test Execution, Reporting, and Metrics</li>
                <li>Agile Testing Methodology and Scrum Framework</li>
                <li>Best Practices for Writing Maintainable Test Cases</li>
                <li>Project Testing and Final Assessment</li>
              </ul>
            </>
          ),
        };
      default:
        return {
          title: "Course Curriculum",
          syllabusDownload: "#",
          courseDescription: <p>Please select a valid course.</p>,
        };
    }
  };

  const courseData = getCourseData();

  return (
    <Container fluid id="curriculum">
      <Container>
        <h3 className="text-center py-5 fw-bold">{courseData.title}</h3>

        {/* Buttons to switch between courses */}
        <div className="text-center mb-4">
          <Button
            variant="btn"
            className="mx-2 text-white fw-bold px-5"
            onClick={() => setCourseType("MERN")}
            style={{ background: '#ed2d16' }}
          >
            MERN Stack
          </Button>
          <Button
            variant="btn"
            className="mx-2 text-white fw-bold px-5"
            onClick={() => setCourseType("Java Full Stack")}
            style={{ background: '#ed2d16' }}

          >
            Java Full Stack
          </Button>
          <Button
            variant="btn"
            className="mx-2 text-white fw-bold px-5"
            onClick={() => setCourseType("Testing")}
            style={{ background: '#ed2d16' }}

          >
            Software Testing
          </Button>
        </div>

        <Row>
          <Col md={8}>
            <div className="d-flex justify-content-between my-5">
              <Col xs={6} className="px-md-2">
                <b>{`Syllabus of ${courseData.title}`}</b>
              </Col>
              <Col xs={6} className="text-end">
                <a
                  href={courseData.syllabusDownload}
                  className="btn rounded-1 col mx-2"
                  download
                  style={{ backgroundColor: "#ed2d16", color: "#fff" }}
                >
                  Download Curriculum <i className="fa fa-download" aria-hidden="true"></i>
                </a>
              </Col>
            </div>

            {courseData.courseDescription}

            <div
              className="my-2 d-flex justify-content-between px-5 py-3"
              style={{ background: "#ed2d16", borderRadius: "8px" }}
            >
              <div>
                <b className="text-white fw-bold">Need Customized Curriculum?</b>
              </div>
              <div>
                <a
                  href="https://wa.link/jphzq1"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-success px-4 btn-sm"
                  style={{
                    background: "#fff",
                    color: "#ed2d16",
                    border: "1px solid #fff",
                    fontWeight: 500,
                    fontSize: "18px",
                    transition: ".3s",
                    textDecoration: "none",
                    borderRadius: "6px",
                  }}
                >
                  <i
                    className="fa fa-whatsapp"
                    aria-hidden="true"
                    style={{ color: "#fff" }}
                  ></i>{" "}
                  Request Now
                </a>
              </div>
            </div>
          </Col>

          <Col md={4}>
            <Card
              className="rounded-0 my-2 col-md-12 shadow"
              style={{
                position: "sticky",
                top: "100px",
                backgroundColor: "#fff",
                border: "none",
                borderRadius: "8px",
              }}
              id="led_gen_form"
            >
              <Card.Body>
                <div className="text-dark text-center my-2">
                  <b>Request More Information</b>
                </div>
                <Form>
                  <Form.Group className="mb-3" controlId="formName">
                    <Form.Control type="text" placeholder="Enter your name" />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Control type="email" placeholder="Enter your email" />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formPhone">
                    <Form.Control type="tel" placeholder="Enter your phone number" />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formMessage">
                    <Form.Control as="textarea" rows={3} placeholder="Enter your message" />
                  </Form.Group>

                  <Button variant="danger" style={{ background: '#ed2d16' }} type="submit" className="w-100">
                    Submit
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Curriculum;
