import React from "react";
import Hireform from "./Hireform";
import "./HireFromUs.css";
import HowItWorks from "./HowItWorks";
import topTalent from "../assets/top-talent.png";
import tieups from "../assets/tie-ups.png";
import trainerpr1 from "../assets/trainer-pr.png";

const HireFromUs = () => {
  return (
    <>
      <div className="hire-container">
        <div className="career-content">
          <div className="career-text">
            <h1 style={{ fontWeight: "200 !important" }}>
              Hire the best
              <br />
              in tech. <br />
            </h1>
            <h2>
              From your MERN Stack team with industry ready skilled tech talent
              at zero cost.
            </h2>
            <button
              style={{
                backgroundColor: "#ed2d16",
                color: "#ffffff",
                padding: "10px 20px",
                borderRadius: "5px",
                cursor: "pointer",
                marginTop: "10px",
                justifyContent: "center",
                margin: "auto",
              }}
            >
              Hire Now
            </button>
          </div>
          <div className="career-image">
            <img src={topTalent} alt="impact-graph" />
          </div>
        </div>
      </div>

      <div className="career-container" style={{ marginTop: "60px" }}>
        <div className="hire-content">
          <div className="career-image">
            <img src={tieups} alt="impact-graph" />
          </div>
          <div className="career-text">
            <h1 style={{ color: "#ed2d16" }}>
              Recruit top-notch tech expertise.
            </h1>
            <h2>
              Our students undergo training in our core full stack MERN Stack
              Development program, making them well suited for various data
              focused roles.
            </h2>
          </div>
        </div>
      </div>

      <div className="hire-container">
        <div className="hire-content">
          <div className="career-text">
            <h1>Pre-assesment by experienced industry specialist</h1>
            <p style={{ fontWeight: "bold" }}>
              At Speedup Infotech, we prioritize the success of our graduates by
              ensuring that all eligible candidates undergo meticulos
              pre-screening conducted by industry experts from Fortune 500
              companies. This rigorous process ensures that our alumni are
              well-prepared and qualified for the demands of the industry.
              Moreover, recruiters receive detailed student performance reports,
              offering comprehensive insights and verification for seamless
              hiring decisions.
            </p>
            <button
              style={{
                backgroundColor: "#ed2d16",
                color: "#ffffff",
                padding: "10px 20px",
                borderRadius: "5px",
                cursor: "pointer",
                marginTop: "10px",
                justifyContent: "center",
                margin: "auto",
              }}
            >
              Hire Now
            </button>
          </div>
          <div className="career-image">
            <img src={trainerpr1} alt="impact-graph" />
            {/* <h2>Pratik Sabale</h2>
            <p>MERN Stack Trainer</p> */}
          </div>
        </div>
      </div>

      <HowItWorks />

      <div className="hire-section">
        <h1>Start hiring the top MERN Stack Talent</h1>
        <div className="hire-details">
          <div className="hire-info">
            <div>
              <h3 style={{ marginTop: "50px" }}>
                &#x2022; Passed intensive Tech assessments
              </h3>
              <h3 style={{ marginTop: "50px" }}>
                &#x2022; Completed real world Capstone projects
              </h3>
              <h3 style={{ marginTop: "50px" }}>
                &#x2022; Completed Mock Interviews
              </h3>
              <h3 style={{ marginTop: "50px" }}>
                &#x2022; Dedicated career coach for mock interviews & career
                guidance
              </h3>
            </div>
            {/* <div>
              <h3 style={{ border: "1px solid black", marginTop: "50px" }}>
                Completed Mock
                <br /> Interviews
              </h3>
              <h3 style={{ border: "1px solid black", marginTop: "70px" }}>
                Dedicated career coach
                <br /> for mock interviews &<br /> career guidance
              </h3>
            </div> */}
          </div>
          {/* <div className="hire-form">
            <Hireform />
          </div> */}
          <Hireform />
        </div>
      </div>
    </>
  );
};

export default HireFromUs;
