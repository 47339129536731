import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import studentworking from "../../assets/df.jpg";
import background2 from "../../assets/background2.jpg";
import studentworking from "../../assets/backgrund2r.jpg";

const FaqSection = () => {
  return (
    <div
      className="container-fluid mt-5"
      id="faq"
      style={{ backgroundColor: "black" }}
    >
      <div className="container py-4">
        <div className="row">
          <div className="col-md-8 py-3 px-4">
            <h4 className="text-white py-3">
              Are you satisfied with our Training Curriculum?
            </h4>
            <p className="text-white fs-6">
              If yes, Register today and get impeccable Learning Solutions!
            </p>
            <div className="row ">
              <form action="/" method="post" >
                <div className="col-md-6 my-2">
                  <input
                    type="text"
                    name="name"
                    className="form-control shadow"
                    placeholder="Name"
                    required
                  />
                </div>
                <div className="col-md-6 my-2 ">
                  <input
                    type="number"
                    name="phone"
                    className="form-control shadow"
                    placeholder="Mobile No*"
                    required
                  />
                </div>
                <div className="col-md-6 my-2">
                  <input
                    type="email"
                    name="email"
                    className="form-control shadow"
                    placeholder="E-Mail"
                    id="exampleInputEmail1"
                    required
                  />
                </div>
                <div className="col-md-6 my-5">
                  <input
                    type="submit"
                    name="submit_Satisfide"
                    value="ENROLL NOW"
                    className="btn btn fw-bold text-light rounded-1 shadow"
                    style={{background:'#ed2d16'}}
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-2 pt-5">
            <img src={studentworking} alt="" width="280" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
